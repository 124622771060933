import './Register.css'
import MyInput from "../../App/MyInput/MyInput";
import Heart from "../../App/Heart/Heart";
import Connection from "../Connection/Connection";
import {Regex} from "../../App/Regex";

class Register extends Heart {
    constructor(props) {
        super(props);
        this.state = {
            alerts: ''
        }
        this.description = <p>Veuillez utiliser une combinaison de 8 caractères, incluant des <strong>minuscules, majuscules, chiffres et caractères spéciaux (+=%#/*?!,.)</strong></p>
    }
    render() {
        this.inputs = []
        return (
            <div className="Register">
                <form className={"row"} onSubmit={(event) => this.handleSubmit(event)} ref={this.refForm}>
                    <MyInput
                        label="Nom"
                        refForm={this}
                        placeholder="DUPONT"
                        autocomplete="family-name"
                        className={"col-6"}
                        id="name"
                        name="name"
                        type="text"
                        required={true}  />
                    <MyInput
                        label="Prénom"
                        refForm={this}
                        placeholder="Louise"
                        autocomplete="given-name"
                        className={"col-6"}
                        id="firstName"
                        name="firstName"
                        type="text"
                        required={true}  />
                    <MyInput
                        label="Adresse mail"
                        refForm={this}
                        placeholder="adresse@mail.com"
                        autocomplete="email"
                        className={"col-12"}
                        id="mail"
                        name="mail"
                        type="text"
                        required={true}
                        regex={Regex.mail}
                        messageRegexError={ 'Veuillez utiliser une adresse mail valide' }
                    />
                    <MyInput
                        label={"Mot de passe"}
                        refForm={this}
                        type={"password"}
                        name={"password"}
                        className={"col-12"}
                        id={"password"}
                        placeholder={"••••••••••••"}
                        autoComplete={"new-password"}
                        description={this.description}
                        required={true}
                        regex={Regex.password}
                        messageRegexError={'Veuillez utiliser un mot de passe valide'}
                    />
                    <p className={"text-center condition"}>
                        <span>En cliquant sur “Créer mon compte”, vous accepter les </span>
                        <a href="/conditions/utilisation" className={"text-primary"}>conditions générales d’utilisation</a>
                        <span> et la </span>
                        <a href="/politique/confidentiel" className={"text-primary"}>politique de confidentialité</a>.</p>
                    {this.state.alerts ?? ''}
                    <button type={"submit"} className="btn btn-primary disabled" disabled={true} ref={this.refSubmit} id={"RegisterAccount"}>Créer mon compte</button>
                </form>
                <p className={"text-center"} style={{fontWeight: 400}}>
                    <span>Vous avez déjà un compte ? </span>
                    <a
                        href={"/utilisateur/connexion"}
                        className={"text-primary"}
                        style={{fontWeight: 600}}
                        onClick={(event) => {
                          event.preventDefault()
                          this.handleNextComponent(<Connection Accueil={this.props.Accueil} App={this.props.App} />)
                        }}
                    >Me connecter</a>
                </p>
            </div>
        );
    }
    handleSubmit(event) {

        /** Annule l'évènement navigateur */
        event.preventDefault();

        /** Déclaration des events */
        let Champ = {
            psNom: document.getElementById("name").value,
            psPrenom: document.getElementById("firstName").value,
            psEmail: document.getElementById("mail").value,
            psMotDePasse: document.getElementById("password").value
        }
        let submit = document.getElementById("RegisterAccount")
        submit.classList.add('disabled')
        submit.setAttribute('disabled', true)

        this.props.App.Utilisateur.Registrer(Champ)
            .then(() => {
                this.setAlert(<>Votre compte unique de l’école des loisirs est maintenant créé. <br />Il vous permettra de vous connecter à tous les sites du groupe école des loisirs.</>, 'success')
                setTimeout(() => {
                    this.handleNextComponent(<Connection Accueil={this.props.Accueil} App={this.props.App} />)
                }, 4000);
            }, sortie => {
                this.setAlert(sortie.message)
                submit.classList.remove('disabled')
                submit.removeAttribute('disabled')
            })
    }
    handleFormChange() {
        this.handleFormChangeAll()
    }
}

export default Register