import './MyInput.css'
import Show from './show.svg'
import Hide from './hide.svg'

import React, {Component} from "react";
import PropTypes from "prop-types";

class MyInput extends Component {
    constructor(props) {
        super(props);

        this.imgShow = <img src={Show} alt="Afficher le mot de passe"/>
        this.imgHide = <img src={Hide} alt="Masquer le mot de passe"/>
        this.show = true
        this.edited = false;
        this.valid = true;
        this.refForm = this.props.refForm
        this.state = {
            type: this.props.type,
            imgToggle: this.imgShow,
            msgError: '',
            validData: '',
            induceFill: this.props.induceFill ?? ''
        }
        this.value = this.props.value
    }
    render() {
        this.value = this.props.value
        let induceFillClass = (this.state.induceFill && this.isEmpty()) ? 'InduceFill' : ''
        return (
            <div className={"InputContainer " + this.props.className + ' ' + induceFillClass}>
                <div className="input-label">
                    <label htmlFor={this.props.id}>{this.props.label}</label>
                    <input
                        className={this.state.validData}
                        type={this.state.type}
                        ref={(e) => {
                            if(e !== null && this.refForm)
                                this.refForm.addInput(e)
                        }}
                        name={this.props.name}
                        id={this.props.id}
                        autoComplete={this.props.autocomplete}
                        placeholder={this.props.placeholder}
                        required={this.props.required}
                        onChange={e => {
                            if(e.currentTarget.value !== "") {
                                this.handleOnChange(e)
                                if(this.props.onChange)
                                    this.props.onChange(e)
                            }
                        }}
                        onKeyUp={e => {
                            if(e.currentTarget.value === "")
                                this.handleOnChange(e)
                                if(this.props.onChange)
                                    this.props.onChange(e)
                        }}
                        data-induce={this.props.induceFill}
                        data-valid={this.Valid()}
                        defaultValue={this.props.value} />
                    <span className={"badgeInduceFill"}><div></div></span>
                    {(this.props.type === 'password') ? <span onClick={this.handleToggle} className={"viewerPassword"}>{this.state.imgToggle}</span> : ''}
                    {this.state.msgError}
                </div>
                {(this.props.description !== '') ? <span className={"description"}>{this.props.description}</span> : ''}
            </div>
        )
    }
    handleOnChange(e) {
        /** Déclaration des variables */
        let current = e.currentTarget
        if(current) {

            /** Mémorise la valeur en cours */
            this.value = current.value

            /** Identifie si les champs sont modifiés ou non */
            this.edited = this.props.value !== current.value
            if(this.props.required === true && this.props.value === "")
                this.edited = true;
            current.setAttribute('data-edited', this.edited)

            current.setAttribute('data-valid', this.Valid())
        }

        /** Désactive l'incitation au remplissage */
        if(this.props.induceFill)
            this.setState({ induceFill: this.isEmpty() })


        /** Contrôle du regex */
        if(this.props.regex) {
            let txt = ''
            let validateRegex = this.validRegex()
            if(this.props.required || (!this.props.required && !this.isEmpty())) {
                if (!validateRegex) {
                    txt = <div className="invalid-feedback msg-alert text-start">
                        {this.props.regex.message}
                    </div>
                }
                this.setState({msgError: txt, validData: validateRegex ? 'is-valid' : 'is-invalid'})
            }
        }

        /** Controle la validité du formulaire */
        if(this.props.refForm.handleFormChange) {
            this.props.refForm.handleFormChange()
        }
        else {
            console.error('La fonction "handleFormChange" n\'est pas définie dans la classe '+this.props.refForm.constructor.name)
        }
    }
    Valid() {
        /** Réinitialise la validité du champ */
        this.valid = true

        /** Test si le champ est obligatoire */
        this.valid = this.validRequired()

        /** Si une valeur est renseignée, on test s'il correspond au Regex */
        if(this.valid === true)
            if(!this.isEmpty())
                this.valid = this.validRegex()

        /** Retour de la validité du champ */
        return this.valid
    }
    isEmpty() {
        let val = this.value;
        if([0, ''].indexOf(val) !== -1) {
            return ([0, ''].indexOf(val.trim()) !== -1)
        }
        return ([0, ''].indexOf() !== -1)
    }
    validRequired() {
        /** Si le champ est obligatoire et qu'il n'est pas vide  */
        if(this.props.required) {
            return !this.isEmpty()
        }
        return true
    }
    validRegex() {
        /** Contrôle du regex */
        if(this.props.regex) {

            if(this.value) {
                /** Si la valeur correspond au regex */
                return (this.value.match(this.props.regex.exp) !== null)
            }
            return false
        }
        return true
    }
    handleToggle = () => {
        this.setState({
            imgToggle: (this.show) ? this.imgHide : this.imgShow,
            type: (this.show) ? 'text' : 'password'
        });
        this.show = !this.show
    }
}
MyInput.propTypes = {
    id: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    type: PropTypes.string,
    name: PropTypes.string.isRequired,
    autocomplete: PropTypes.string,
    placeholder: PropTypes.string,
    description: PropTypes.object,
    regex: PropTypes.object,
    required: PropTypes.bool,
    induceFill: PropTypes.bool,
    refForm: PropTypes.object
}
MyInput.defaultProps = {
    type: 'text',
    autocomplete: 'off',
    description: null,
    placeholder: '',
    className: '',
    required: false,
    induceFill: false
}

export default MyInput