import './ForgetPassword.css'

import Heart from "../../App/Heart/Heart";
import MyInput from "../../App/MyInput/MyInput";
import Connection from "../Connection/Connection";
import {Regex} from "../../App/Regex";
import ResetPasswordSend from "../ResetPasswordSend/ResetPasswordSend";

class ForgetPassword extends Heart {
    default = ''
    constructor(props) {
        super(props);
        console.log(props);
        this.state = {
            alerts: ''
        }
    }
    render() {
        this.inputs = []
        return (
            <div className="MotdepasseOublier">
                <p className={"indication"}>Pour réinitialiser votre mot de passe, <br/>veuillez renseigner votre adresse mail :</p>
                <form onSubmit={(e) => this.handleSubmit(e)} rel={this.refForm}>
                    <MyInput
                        label="Adresse mail"
                        placeholder="adresse@mail.com"
                        autocomplete="username"
                        refForm={this}
                        id="mail"
                        name="mail"
                        type="text"
                        required={true}
                        regex={Regex.mail}
                    />
                    {this.state.alerts ?? ''}
                    <button className="btn btn-primary disabled" disabled={true} type={"submit"} ref={this.refSubmit}>Envoyer</button>
                </form>
                <p className={"retour"}>
                    <a
                        href={"/utilisateur/connexion"}
                        onClick={(e) => {
                            e.preventDefault()
                            /**
                             * TODO - METHODE A SUPPRIMER | VOIR POUR METTRE EN PLACE LE ROUTEUR SUR L'ENSEMBLE DU SITE
                             * **/
                            // this.handleNextComponent(<Connection Accueil={this.props.Accueil} App={this.props.App} />)
                            window.location.replace(window.location.origin);
                        }}
                        className={"text-black"}>
                        Retour
                    </a>
                </p>
            </div>
        );
    }
    handleSubmit(e) {

        e.preventDefault()

        /** Déclaration des variables */
        let email = document.getElementById('mail')

        this.props.App.Utilisateur.ForgetPassword(email.value).then(sortie => {
            this.props.Accueil.switchContainer(
                <ResetPasswordSend message={sortie.message} Accueil={this.props.Accueil} App={this.props.App} />
            )
        }, sortie => {
            this.setAlert(sortie.message)
        })
    }
    handleFormChange() {
        this.handleFormChangeAll()
    }
}

export default ForgetPassword